import { UploadOutlined }                      from '@ant-design/icons';
import { Button, Upload, Typography, message } from 'antd';
import React, { useState, useEffect }          from 'react'
import { useNavigate, useParams }              from 'react-router-dom';
import axios                                   from '../Authentication/headers';
import openNotificationWithIcon                from '../ResuseableComponnets/Toaster';
import { getSegmentById }                      from './APIS/Apis';
import Header                                  from "./Reuseable/Header";


const { Text, Link } = Typography;
const FileSegment = () => {
  const [state, setState] = useState({
    data:null
  })
  const navigate = useNavigate()
  const [fileList, setFileList] = useState([]);
  const { id, name } = useParams()
  const handleChange = (info) => {
    const { fileList: newFileList } = info;

    if (newFileList.length > 1) {
      newFileList.splice(1, newFileList.length - 1);
    }

    setFileList(newFileList);
  };

  const handleSubmit = () => {
    if (fileList.length === 0) {
      message.warning("Please upload a file before submitting.");
      return;
    }

    const formData = new FormData();
    formData.append("list", fileList[0].originFileObj);
    formData.append("data", JSON.stringify({
      name: !!id ? state.data.name : name,
      category: "list_upload"
    }))

    if(!!id){
      axios.put(`https://backend.tiebot.io/segment/update/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${ localStorage.getItem('token') }`,
        },
      }).then(() => {
        navigate("/segments")
        openNotificationWithIcon('success',
          'File has been updated',
          'File has been updated'
        )
      }).catch(err => {
          openNotificationWithIcon('error',
            'Error Message',
            err?.response?.data?.message
          )
        }
      )
    } else {
      axios.post("https://backend.tiebot.io/segment/create", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${ localStorage.getItem('token') }`,
        },
      }).then(response => {
        navigate("/segments")
        openNotificationWithIcon('success',
          'File has been uploaded',
          'File has been uploaded'
        )
      }).catch(err => {
          openNotificationWithIcon('error',
            'Error Message',
            err?.response?.data?.message
          )
        }
      )
    }
  };
  const getDataFunc = async () => {
    let response = await getSegmentById(id)
    try {
      if(response.data.data) {
        setState({
          data: response.data.data
        })
      } else {
        openNotificationWithIcon('error',
          'Error Message',
          "Error"
        )
      }
    } catch (err) {
        openNotificationWithIcon('error',
          'Error Message',
          err?.response?.data?.message
        )
      }
    }

  useEffect(() => {
    !!id && getDataFunc()
  }, []);
  return (
    <div className="synctic-module-container" style={ { padding: '20px' } }>
      <Header
        name="Segment Manger"
        Continue="Submit"
        clickFunction={ () => handleSubmit() }
      />
      <div className='cards-box' style={ {
        marginTop: 10,
        border: '1px solid #E2E8F0',
        borderRadius: '0.375rem',
        padding: '1rem',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
      } }>
        <div style={ { marginBottom: '24px' } }>
          <h2
            style={ {
              fontSize: '1.125rem',
              fontWeight: '500',
              marginBottom: '0.5rem',
            } }
          >List Upload</h2>
          <p style={ { marginBottom: '16px', fontSize: '0.875rem' } }>Upload contacts list to whom you want to send a
            message.</p>
          <Text type="danger" style={ { display: 'block', marginBottom: '24px' } }>
            Note: Please upload a list of maximum 1000 contacts at a time.
          </Text>
        </div>

        <div style={ { marginBottom: '16px' } }>
          <Text style={ { display: 'block', marginBottom: '8px' } }>Select File to Upload</Text>
          <Upload
            maxCount={ 1 }
            fileList={ fileList }
            onChange={ handleChange }
            beforeUpload={ () => false }
            className="custom-upload"
            style={ { width: "100%", display: "block" } }
          >
            <Button icon={ <UploadOutlined/> } style={ { marginRight: "8px" } }>
              {
                id ? "Change file" : "Choose file"
              }
            </Button>
          </Upload>
        </div>
        {
          id && <Button href={state.data?.file?.url} type={"primary"} size={"large"}>Download File</Button>
        }
        <div style={ { marginTop: '24px' } }>
          <Text style={ { display: 'block' } }>
            Download our{ ' ' }
            <Link href="#" style={ { color: '#1890ff' } }>
              sample CSV file
            </Link>{ ' ' }
            and replace the sample contacts with your own data.
          </Text>
        </div>
      </div>
    </div>
  )
}

export default FileSegment
